import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { ProspectHumanResult } from "@safelease/service-utilities";
import { useState } from "react";
import { SearchByWebsiteMenu } from "../SearchByWebsiteMenu";
import { useFormContext } from "react-hook-form";
import { ExpandMore } from "@mui/icons-material";
import { RadioButtonGroupForm } from "./RadioButtonGroupForm";

export function WebsiteAccordion({ prospectHumanResult }: { prospectHumanResult: ProspectHumanResult }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { register, setValue, watch } = useFormContext();
  const websiteNotFound = watch("websiteNotFound");

  return (
    <Accordion sx={{ boxShadow: "none", "&:before": { display: "none" }, padding: 1 }} defaultExpanded>
      {/* accordion header */}
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography fontWeight="bold">Website</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {/* website url input field */}
          {!websiteNotFound && (
            <Grid item xs={12}>
              <RadioButtonGroupForm
                fieldName="website"
                defaultValueNew={prospectHumanResult.websiteNew || "No Website"}
                defaultValueOld={prospectHumanResult.websiteOld || "No Website"}
              />
            </Grid>
          )}

          {/* no website found checkbox */}
          <Grid item container alignItems="center" justifyContent="space-between">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("websiteNotFound")}
                    onChange={(e) => {
                      setValue("websiteNotFound", e.target.checked);
                      if (e.target.checked) {
                        setValue("website", "");
                      }
                    }}
                  />
                }
                defaultChecked={websiteNotFound}
                label="No website found"
                checked={websiteNotFound}
              />
            </Grid>

            {/* find website dropdownbutton */}
            <Grid item>
              <Button
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
                variant="text"
                endIcon={<ExpandMore />}
              >
                Find website
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>

      {/* Find website menu options */}
      <SearchByWebsiteMenu prospectHumanResult={prospectHumanResult} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
    </Accordion>
  );
}
