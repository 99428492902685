import { GridColDef } from "@mui/x-data-grid-premium";
import dayjs from "dayjs";

export const mostStaleJobByFMSColumnDefs: GridColDef[] = [
  {
    field: "type",
    headerName: "FMS",
    width: 100,
  },
  {
    field: "mostStale",
    headerName: "Latency",
    renderCell: (value) => {
      return dayjs(value.value).format("MMM DD YYYY hh:mm A");
    },
    width: 200,
  },
  {
    field: "hoursOld",
    headerName: "Hours Stale",
    valueGetter: (params) => {
      return dayjs().diff(dayjs(params.row.mostStale), "hours");
    },
  },
];
