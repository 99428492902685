import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { RadioButtonGroupForm } from "./RadioButtonGroupForm";
import { ProspectHumanResult } from "@safelease/service-utilities";
import { useFormContext } from "react-hook-form";

export function PlaceIdAccordion({ prospectHumanResult }: { prospectHumanResult: ProspectHumanResult }) {
  const { register, setValue, watch } = useFormContext();
  const placeIdNotFound = watch("placeIdNotFound");

  return (
    <Accordion sx={{ boxShadow: "none", "&:before": { display: "none" }, padding: 1 }} defaultExpanded>
      {/* accordion header */}
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography fontWeight="bold">Place Id Finder</Typography>
      </AccordionSummary>

      {/* accordion details */}
      <AccordionDetails>
        <Grid container spacing={2}>
          {/* place id input field */}
          {!placeIdNotFound && (
            <Grid item xs={12}>
              <RadioButtonGroupForm
                fieldName="placeId"
                defaultValueNew={prospectHumanResult.placeIdNew || "No Place Id"}
                defaultValueOld={prospectHumanResult.placeIdOld || "No Place Id"}
              />
            </Grid>
          )}

          {/* place id iframe */}
          <Grid item xs={12}>
            <Box sx={{ height: 300 }}>
              <iframe
                style={{ height: "100%", width: "100%" }}
                src="https://geo-devrel-javascript-samples.web.app/samples/places-placeid-finder/app/dist/"
              />
            </Box>
          </Grid>

          {/* no placeId found checkbox */}
          <Grid item container alignItems="center" justifyContent="space-between">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("placeIdNotFound")}
                    onChange={(e) => {
                      setValue("placeIdNotFound", e.target.checked);
                      if (e.target.checked) {
                        setValue("placeId", "");
                      }
                    }}
                  />
                }
                defaultChecked={placeIdNotFound}
                label="No place id found"
                checked={placeIdNotFound}
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
