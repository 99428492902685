import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Controller, useFormContext } from "react-hook-form";

interface PlaceFinderAccordionProps {
  updateActivityCount: () => void;
}

export function PlaceFinderAccordion({ updateActivityCount }: PlaceFinderAccordionProps) {
  const { control, watch, register, setValue } = useFormContext();
  const placeIdNotFound = watch("placeIdNotFound");

  return (
    <Accordion sx={{ boxShadow: "none", "&:before": { display: "none" }, padding: 1 }} defaultExpanded>
      {/* accordion header */}
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography fontWeight="bold">Place Id Finder</Typography>
      </AccordionSummary>

      {/* accordion details */}
      <AccordionDetails>
        <Grid container spacing={2}>
          {/* place id input field */}
          <Grid item xs={12}>
            <Controller
              name="placeId"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Enter Place Id"
                  disabled={placeIdNotFound}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    updateActivityCount();
                  }}
                />
              )}
            />
          </Grid>

          {/* place id iframe */}
          <Grid item xs={12}>
            <Box sx={{ height: 300 }}>
              <iframe
                style={{ height: "100%", width: "100%" }}
                src="https://geo-devrel-javascript-samples.web.app/samples/places-placeid-finder/app/dist/"
              />
            </Box>
          </Grid>

          {/* place id not found checkbox */}
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  {...register("placeIdNotFound")}
                  onChange={(e) => {
                    setValue("placeIdNotFound", e.target.checked);
                    if (e.target.checked) {
                      setValue("placeId", "");
                    }
                    updateActivityCount();
                  }}
                />
              }
              defaultChecked={placeIdNotFound}
              label="No place id found"
              checked={placeIdNotFound}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
