import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ClaimsApi } from "../../../../../utils/apiInstances/ClaimsApiInstance";
import { Skeleton } from "@mui/material";
import { ClaimsTable } from "./ClaimsTable";

type ActiveClaimsTableProps = {};

function ActiveClaimsTable({}: ActiveClaimsTableProps) {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 50 });

  const claimsQuery = useQuery({
    queryKey: [
      "getClaimsForNavigatorTablesByPage",
      { page: paginationModel.page, limit: paginationModel.pageSize, statusFilterType: "active" },
    ],
    queryFn: async () => {
      const response = await ClaimsApi.getClaimsForNavigatorTablesByPage({
        page: paginationModel.page.toString(),
        limit: paginationModel.pageSize.toString(),
        statusFilterType: "active",
      });
      return response.data;
    },
  });

  if (claimsQuery.isLoading || claimsQuery.isRefetching) {
    return <Skeleton variant="rounded" sx={{ width: "100%", height: "100%" }} />;
  }

  return (
    <ClaimsTable
      claimsList={claimsQuery.data?.returnList ?? []}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      totalRows={claimsQuery.data?.totalRows}
    />
  );
}

export { ActiveClaimsTable };
