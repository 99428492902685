import {
  Button,
  Chip,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProspectsApi } from "../../../utils/apiInstances/ProspectsApiInstance";
import { Close } from "@mui/icons-material";
import { WebsiteAccordion } from "../components/reviewers/WebsiteAccordion";
import { FacilityOverviewAccordion } from "../components/reviewers/FacilityOverviewAccordion";
import { ProspectsTabEnum } from "./ProspectsPage";
import { PlaceFinderAccordion } from "../components/reviewers/PlaceFinderAccordion";
import { isStorageFacilityMap, prospectHumanStatusMap } from "../constants";
import { ProspectsReviewDetailsDrawerFormData } from "../../../types/Prospects";
import { enqueueSnackbar } from "notistack";
import { Controller, FormProvider, useForm } from "react-hook-form";

export interface ProspectsReviewDetailsDrawerErrors {
  notes: boolean;
  googleStreetViewUrl: boolean;
  isStorageFacility: boolean;
}

export function ProspectsReviewDetailsDrawer() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reviewProspectId = searchParams.get("id");
  const form = useForm<ProspectsReviewDetailsDrawerFormData>({
    defaultValues: {
      placeId: "",
      website: "",
      name: "",
      nameActivityCount: 0,
      websiteActivityCount: 0,
      placeIdActivityCount: 0,
      notes: "",
      placeIdNotFound: false,
      websiteNotFound: false,
      nameNotFound: false,
      googleStreetViewUrl: "",
      isStorageFacility: "",
    },
  });
  const queryClient = useQueryClient();

  const {
    data: prospectHumanResult,
    isLoading: isProspectHumanResultLoading,
    refetch: refetchProspectHumanResult,
  } = useQuery({
    queryKey: ["getProspectHumanResultDetails", reviewProspectId],
    queryFn: async () => {
      if (!reviewProspectId) {
        // if we don't have a reviewProspectId, navigate to the 404 page
        navigate("/404");
        return null;
      }

      // I am using a try catch here because I want to catch any errors and navigate to the 404 page
      try {
        const response = await ProspectsApi.getProspectHumanResultById(reviewProspectId);
        form.reset({
          placeId: response.data.placeIdNew || response.data.placeIdOld || "",
          website: response.data.websiteNew || response.data.websiteOld || "",
          name: response.data.nameNew || response.data.nameOld || "",
          nameActivityCount: response.data.nameActivityCount || 0,
          websiteActivityCount: response.data.websiteActivityCount || 0,
          placeIdActivityCount: response.data.placeIdActivityCount || 0,
          notes: response.data.notes || "",
          placeIdNotFound: response.data.placeIdNotFound || false,
          websiteNotFound: response.data.websiteNotFound || false,
          nameNotFound: response.data.nameNotFound || false,
          googleStreetViewUrl: response.data.googleStreetViewUrl || "",
          isStorageFacility: response.data.isStorageFacility || "",
        });

        return response.data;
      } catch (error) {
        console.log(error, "error");

        // if we have a network error, navigate to the 404 page
        navigate("/404");
        return null;
      }
    },
  });

  const onSubmitDetails = async (formData: ProspectsReviewDetailsDrawerFormData) => {
    onUpdateProspectHumanResult.mutate(formData);
  };

  const submitDetails = async (formData: ProspectsReviewDetailsDrawerFormData) => {
    const isStorageFacility = formData.isStorageFacility === "noPlaceIdFound" ? "unknown" : formData.isStorageFacility;

    // this is a type check, we should never get here if we don't have a reviewProspectId
    if (!reviewProspectId) {
      return;
    }

    // this is a type check, we should never get here if we don't have a reviewProspectId
    if (!reviewProspectId) {
      return;
    }

    await ProspectsApi.updateProspectHumanResultByIdForReviewer(reviewProspectId, {
      ...formData,
      // We do not want to store empty strings in the database
      nameNew: formData.name || null,
      nameActivityCount: formData.nameActivityCount,
      websiteNew: formData.website || null,
      websiteActivityCount: formData.websiteActivityCount,
      placeIdNew: formData.placeId || null,
      placeIdActivityCount: formData.placeIdActivityCount,
      notes: formData.notes || null,
      googleStreetViewUrl: formData.googleStreetViewUrl || null,
      isStorageFacility: isStorageFacility || null,
    });
  };

  const onUpdateProspectHumanResult = useMutation({
    mutationFn: async (formData: ProspectsReviewDetailsDrawerFormData) => {
      submitDetails(formData);
    },
    onSuccess: async () => {
      // refetch the prospect human result table and details
      refetchProspectHumanResult();
      // weird race condition cache issue, invalidate and refetch the prospect human results for reviewer
      await queryClient.invalidateQueries({ queryKey: ["getProspectHumanResultsForReviewer"] });
      await queryClient.refetchQueries({ queryKey: ["getProspectHumanResultsForReviewer"] });
      // close the drawer on success
      navigate(`/prospects/${ProspectsTabEnum.REVIEW}`);
    },
    onError: () => {
      enqueueSnackbar("An error occurred while marking the prospect as done", { variant: "error" });
      enqueueSnackbar("An error occurred while marking the prospect as done", { variant: "error" });
    },
  });

  if (isProspectHumanResultLoading) {
    return <CircularProgress />;
  }

  // this is a type check, we should never get here if we don't have a reviewProspectId
  if (!prospectHumanResult) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      onClose={() => navigate(`/prospects/${ProspectsTabEnum.REVIEW}`)}
      open={!!reviewProspectId}
      PaperProps={{ sx: { width: "70%" } }}
    >
      <Grid container>
        {/* header */}
        <Grid item container justifyContent="space-between" padding={2}>
          <Grid item container xs={9} rowGap={1}>
            <Grid item xs={12}>
              <Typography variant="h6">{prospectHumanResult.nameNew || prospectHumanResult.nameOld || "No name"}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Chip
                color={prospectHumanStatusMap[prospectHumanResult.status as keyof typeof prospectHumanStatusMap].color}
                label={prospectHumanStatusMap[prospectHumanResult.status as keyof typeof prospectHumanStatusMap].label}
              />
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={() => navigate(`/prospects/${ProspectsTabEnum.REVIEW}`)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        {/* form */}
        <Grid item xs={12}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmitDetails)}>
              {/* facility overview accordion */}
              <Grid item xs={12}>
                <FacilityOverviewAccordion
                  prospectHumanResult={prospectHumanResult}
                  updateActivityCount={() => form.setValue("nameActivityCount", form.getValues("nameActivityCount") + 1)}
                />
              </Grid>

              {/* website accordion */}
              <Grid item xs={12}>
                <WebsiteAccordion
                  prospectHumanResult={prospectHumanResult}
                  updateActivityCount={() => form.setValue("websiteActivityCount", form.getValues("websiteActivityCount") + 1)}
                />
              </Grid>

              {/* place id accordion */}
              <Grid item xs={12}>
                <PlaceFinderAccordion
                  updateActivityCount={() => form.setValue("placeIdActivityCount", form.getValues("placeIdActivityCount") + 1)}
                />
              </Grid>

              {/* is storage facility questions */}
              <Grid item container padding={3} rowGap={2}>
                {/* is storage facility */}
                <Grid item xs={12}>
                  <Controller
                    name="isStorageFacility"
                    control={form.control}
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel>Is this a storage facility?</FormLabel>
                        <RadioGroup {...field}>
                          {Object.entries(isStorageFacilityMap).map(([key, value]) => (
                            <FormControlLabel required key={key} value={key} control={<Radio />} label={value} />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                </Grid>

                {/* google street view url */}
                <Grid item xs={12}>
                  <Controller
                    name="googleStreetViewUrl"
                    control={form.control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <TextField required fullWidth label="Google Street View URL" {...field} />
                      </FormControl>
                    )}
                  />
                </Grid>

                {/* notes */}
                <Grid item xs={12}>
                  <Controller
                    name="notes"
                    control={form.control}
                    render={({ field }) => (
                      <TextField
                        placeholder="Where did you find the information? Please list the search engine (Google, Yahoo, etc.) used, the URL, and the steps for why and how you found the information."
                        required
                        fullWidth
                        label="Notes"
                        multiline
                        rows={4}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* submit to moderator button */}
              <Grid item xs={12} padding={2} paddingBottom={4}>
                <Button fullWidth type="submit" variant="contained" color="primary">
                  Mark as Done
                </Button>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Drawer>
  );
}
