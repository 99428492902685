import { Metric, SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import _ from "lodash";
import { Box, Divider, Stack } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { ClaimDetailsDrawerType, useClaimDetailsDrawerStore } from "../../DetailsDrawer/useClaimDetailsDrawerStore";
import { AdjusterValueMetric } from "./AdjusterValueMetric";
import { ClaimDocumentViewerModal } from "./ClaimDocumentViewerModal/ClaimDocumentViewerModal.tsx";
import { useState } from "react";

type MetricsAndActionsCardProps = {};

function MetricsAndActionsCard({}: MetricsAndActionsCardProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const setDrawerOpen = useClaimDetailsDrawerStore((state) => state.setDrawerOpen);
  const setCurrentDrawerType = useClaimDetailsDrawerStore((state) => state.setCurrentDrawerType);
  const [documentViewerModalOpen, setDocumentViewerModalOpen] = useState<boolean>(false);

  const actionButtonClicked = (drawerType: ClaimDetailsDrawerType) => {
    setCurrentDrawerType(drawerType);
    setDrawerOpen(true);
  };

  return (
    <SafeLeaseCard
      sx={{
        padding: "10px",
        boxShadow: 0,
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: "10px",
          boxShadow: 0,
          overflow: "auto",
        }}
      >
        <Stack direction="row" spacing={3} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
          {/* Couldnt use the regular Metric component because we need adjuster value to be able to become a textfield to set the value by the adjuster */}
          <AdjusterValueMetric />
          <Metric type="currency" value={_.sumBy(claim?.lineItems, "requestedAmount") / 100} label="Requested payout" />
          <Metric value={claim?.lineItems?.length || 0} label="Items" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box>
            <SafeLeaseButton
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => actionButtonClicked(ClaimDetailsDrawerType.NOTES)}
              size="small"
              variant="outlined"
              color="navy"
              startIcon={<DescriptionOutlinedIcon />}
              endIcon={<ChevronRightOutlinedIcon />}
            >
              Notes
            </SafeLeaseButton>
          </Box>
          <Box>
            <SafeLeaseButton
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => setDocumentViewerModalOpen(true)}
              size="small"
              variant="outlined"
              color="navy"
              startIcon={<InsertDriveFileOutlinedIcon />}
              endIcon={<ChevronRightOutlinedIcon />}
            >
              Documents
            </SafeLeaseButton>
          </Box>
          <Box>
            <SafeLeaseButton
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => actionButtonClicked(ClaimDetailsDrawerType.AD_HOC_REQUESTS)}
              size="small"
              variant="outlined"
              color="navy"
              startIcon={<InsertDriveFileOutlinedIcon />}
              endIcon={<ChevronRightOutlinedIcon />}
            >
              Ad-hoc requests
            </SafeLeaseButton>
          </Box>
          <Box>
            <SafeLeaseButton
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => actionButtonClicked(ClaimDetailsDrawerType.CLAIM_FORM_DETAILS)}
              size="small"
              variant="outlined"
              color="navy"
              startIcon={<DescriptionOutlinedIcon />}
              endIcon={<ChevronRightOutlinedIcon />}
            >
              Claim form
            </SafeLeaseButton>
          </Box>
          <Box>
            <SafeLeaseButton
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => actionButtonClicked(ClaimDetailsDrawerType.TENANT_MESSAGING)}
              size="small"
              variant="outlined"
              color="navy"
              startIcon={<ChatBubbleOutlineOutlinedIcon />}
              endIcon={<ChevronRightOutlinedIcon />}
            >
              With tenant
            </SafeLeaseButton>
          </Box>
        </Stack>
      </Stack>
      <ClaimDocumentViewerModal modalOpen={documentViewerModalOpen} setModalOpen={setDocumentViewerModalOpen} />
    </SafeLeaseCard>
  );
}

export { MetricsAndActionsCard };
