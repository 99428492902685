import { ProspectHumanResult } from "@safelease/service-utilities";

// handles both place and sfdcLocation address fields and returns a string
export function determineProspectHumanResultAddress(prospectHumanResult: ProspectHumanResult) {
  return prospectHumanResult?.prospect?.place?.address
    ? `${prospectHumanResult?.prospect?.place?.address}, ${prospectHumanResult?.prospect?.place?.city}, ${prospectHumanResult?.prospect?.place?.state} ${prospectHumanResult?.prospect?.place?.postalCode}`
    : prospectHumanResult?.prospect?.sfdcLocation?.address
      ? `${prospectHumanResult?.prospect?.sfdcLocation?.address}, ${prospectHumanResult?.prospect?.sfdcLocation?.city}, ${prospectHumanResult?.prospect?.sfdcLocation?.state} ${prospectHumanResult?.prospect?.sfdcLocation?.postalCode}`
      : "No Address";
}

// opens a new tab to google search with the query provided
export const openTabToGoogleSearch = (query: string) => {
  const searchParams = new URLSearchParams({ q: query });
  const googleSearchUrl = `https://www.google.com/search?${searchParams.toString()}`;
  window.open(googleSearchUrl, "_blank");
};
