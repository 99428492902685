import { SafeLeaseLetterAvatar as LetterAvatar } from "@safelease/components";
import PriorityIcon from "../../../components/ServiceUIComponents/JobErrorService/PriorityIcon";
import { editableField } from "../../../utils/helpers/editable-field";
import { Warning as WarningIcon, FormatListBulleted as FormatListBulletedIcon } from "@mui/icons-material";
import { GridColDef, GridRowParams, GridValueGetterParams } from "@mui/x-data-grid-premium";
import { Stack, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import getJobErrorActions from "./JobErrorActions";
import { JobErrorCenterApi } from "../../../utils/apiInstances/JobErrorCenterApiInstance";
import dayjs from "dayjs";

export enum Teams {
  Success = "success",
  Support = "support",
  Product = "product",
}

// TODO controlled team updates
export const jobsErrorReportGridColDefs: GridColDef[] = [
  {
    field: "team",
    headerName: "Team",
    width: 80,
    editable: true,
    type: "singleSelect",
    valueOptions: () => [
      { value: Teams.Success, label: "Success" },
      { value: Teams.Support, label: "Support" },
      { value: Teams.Product, label: "Product" },
    ],
    valueGetter: (params: GridValueGetterParams) => {
      return params.value;
    },
  },
  {
    field: "assignee",
    headerName: "Assignee",
    width: 70,
    renderCell: ({ row }) => {
      return <LetterAvatar name={row?.assignee?.name || "Unassigned"} sx={{ m: 1 }} />;
    },
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.assignee?.name;
    },
  },
  {
    field: "relationshipName",
    headerName: "Relationship Name",
    width: 200,
    groupable: true,
  },
  {
    field: "locationAddress",
    headerName: "Location",
  },
  {
    field: "failedAt",
    headerName: "Failed At",
    width: 300,
    renderCell: (value) => (value ? dayjs(value.value).format("ddd MMM DD YYYY hh:mm:ss A") : ""),
  },
  {
    field: "method",
    headerName: "Method",
    width: 130,
  },
  {
    field: "triagingStatus",
    headerName: "Triaging Status",
    width: 120,
    align: "center",
    renderCell: ({ value }) => <PriorityIcon priority={value} />,
  },
  {
    field: "error",
    headerName: "Error",
    flex: 1,
    minWidth: 350,
    renderCell: (value) => (
      <div className=" tw-w-full tw-break-words">
        {value?.value?.slice(0, 100)}
        {value?.value?.length > 100 ? "..." : ""}
      </div>
    ),
  },
  {
    field: "errorNotes",
    headerName: "Error Notes",
    width: 400,
    renderCell: (value) => <div className="tw-w-full tw-break-words">{value.value}</div>,
  },
  {
    field: "fms",
    headerName: "FMS",
    width: 120,
    groupable: true,
  },
  {
    field: "locationId",
    headerName: "Location ID",
    width: 120,
    groupable: true,
  },
  {
    field: "csmNotes",
    headerName: "CSM Notes",
    width: 400,
    renderCell: (value) =>
      editableField(
        value.value,
        { row: value.row, queryResult: value.row.queryResult },
        {
          callback: async (newValue: string, tableInfo: { row: any; queryResult: any }) => {
            await JobErrorCenterApi.updateJob({
              csmNotes: newValue,
              jobId: tableInfo.row.id.toString(),
            });
            await tableInfo.queryResult.refetch();
          },
          nonAdminsCannotEdit: true,
        },
      ),
  },
  {
    field: "csmResolutionSteps",
    headerName: "CSM Resolution Steps",
    minWidth: 400,
    renderCell: (value) => <div className="tw-w-[20rem] tw-break-words">{value.value}</div>,
  },
  {
    field: "id",
    headerName: "Actions",
    headerAlign: "center",
    renderHeader: () => null,
    align: "center",
    type: "actions",
    width: 50,
    getActions: (params: GridRowParams) =>
      getJobErrorActions({
        jobId: params.id.toString(),
        jobError: params.row,
        navigate: params.row.navigate,
        fetchJobErrors: params.row.fetchJobErrors,
      }),
  },

  // Notes field renders as icons that are highlighed when the notes exist
  // To view notes, open click the row to open the drawer
  {
    field: "notes",
    width: 80,
    headerName: "Notes",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => (
      <Stack direction="row" spacing={1}>
        <Tooltip title="Error notes" disableHoverListener={!row.errorNotes}>
          <WarningIcon sx={{ color: row.errorNotes ? grey[600] : grey[200] }} />
        </Tooltip>
        <Tooltip title="CSM Resolution Steps" disableHoverListener={!row.csmResolutionSteps}>
          <FormatListBulletedIcon sx={{ color: row.csmResolutionSteps ? grey[600] : grey[200] }} />
        </Tooltip>
      </Stack>
    ),
  },
];
