import PriorityIcon from "../../../components/ServiceUIComponents/JobErrorService/PriorityIcon";
import { editableField } from "../../../utils/helpers/editable-field";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import getJobErrorActions from "./JobErrorActions";
import dayjs from "dayjs";
import { JobErrorCenterApi } from "../../../utils/apiInstances/JobErrorCenterApiInstance";

export const liveProductionJobReportColumnDefs: GridColDef[] = [
  {
    field: "relationshipName",
    headerName: "Relationship Name",
    width: 200,
    groupable: true,
  },
  {
    field: "locationAddress",
    headerName: "Location",
    width: 200,
  },
  {
    field: "failedAt",
    headerName: "Failed At",
    width: 300,
    // Sat Nov 11 2023 12:00:00 AM format
    renderCell: ({ value }) => dayjs(value).format("MMM DD YYYY hh:mm A"),
  },
  {
    field: "method",
    headerName: "Method",
    width: 150,
  },
  {
    field: "fms",
    headerName: "FMS",
    width: 100,
    groupable: true,
  },
  {
    field: "locationId",
    headerName: "Location ID",
    width: 90,
    groupable: true,
  },
  {
    field: "triagingStatus",
    headerName: "Triaging Status",
    width: 120,
    align: "center",
    renderCell: ({ value }) => <PriorityIcon priority={value} />,
  },
  {
    field: "error",
    headerName: "Error",
    flex: 1,
    minWidth: 350,
    renderCell: (value) => (
      <div className=" tw-w-full tw-break-words">
        {value?.value?.slice(0, 100)}
        {value?.value?.length > 100 ? "..." : ""}
      </div>
    ),
  },
  {
    field: "errorNotes",
    headerName: "Error Notes",
    width: 400,
    renderCell: (value) => <div className="tw-w-full tw-break-words">{value.value}</div>,
  },
  {
    field: "csmNotes",
    headerName: "CSM Notes",
    width: 400,
    renderCell: (value) =>
      editableField(
        value.value,
        { row: value.row, queryResult: value.row.queryResult },
        {
          callback: async (newValue: string, tableInfo: { row: any; queryResult: any }) => {
            await JobErrorCenterApi.updateJob({
              csmNotes: newValue,
              jobId: tableInfo.row.id.toString(),
            });
            await tableInfo.row.fetchJobErrors();
          },
          nonAdminsCannotEdit: true,
        },
      ),
  },
  {
    field: "csmResolutionSteps",
    headerName: "CSM Resolution Steps",
    minWidth: 400,
    renderCell: (value) => <div className="tw-w-[20rem] tw-break-words">{value.value}</div>,
  },
  {
    field: "id",
    headerName: "Actions",
    headerAlign: "center",
    renderHeader: () => null,
    align: "center",
    type: "actions",
    width: 50,
    getActions: (params: GridRowParams) =>
      getJobErrorActions({
        jobId: params.id.toString(),
        jobError: params.row,
        navigate: params.row.navigate,
        fetchJobErrors: params.row.fetchJobErrors,
      }),
  },
];
