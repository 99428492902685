import { ArrowOutward } from "@mui/icons-material";
import { Grid, Menu, MenuItem } from "@mui/material";
import { ProspectHumanResult } from "@safelease/service-utilities";
import { determineProspectHumanResultAddress, openTabToGoogleSearch } from "../../../utils/helpers/prospects";

interface SearchByWebsiteMenuProps {
  prospectHumanResult: ProspectHumanResult;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  // optional onClick handler for something like counting clicks for prospectHumanResult reviewer metrics
  onClick?: () => void;
}

export function SearchByWebsiteMenu({ prospectHumanResult, anchorEl, onClose, onClick }: SearchByWebsiteMenuProps) {
  const address = determineProspectHumanResultAddress(prospectHumanResult);
  const name = prospectHumanResult?.nameNew || prospectHumanResult?.nameOld;

  const searchMenuItems = [
    { label: "Search by address", onClick: () => openTabToGoogleSearch(address) },
    ...(name ? [{ label: "Search by name + address", onClick: () => openTabToGoogleSearch(`${name} ${address}`) }] : []),
    { label: "Search by address + 'storage'", onClick: () => openTabToGoogleSearch(`${address} storage`) },
  ];

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {searchMenuItems.map((item) => {
        return (
          <MenuItem
            onClick={() => {
              onClose();
              item.onClick();
              onClick?.();
            }}
            key={item.label}
          >
            <Grid container alignItems="center" spacing={1} justifyContent="space-between">
              <Grid item>{item.label}</Grid>
              <Grid item>
                <ArrowOutward color="inherit" />
              </Grid>
            </Grid>
          </MenuItem>
        );
      })}
    </Menu>
  );
}
