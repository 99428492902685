import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import CopyAddress from "../CopyAddress";
import { Controller, useFormContext } from "react-hook-form";
import { determineProspectHumanResultAddress } from "../../../../utils/helpers/prospects";
import { ProspectHumanResult } from "@safelease/service-utilities";

interface FacilityOverviewAccordionProps {
  prospectHumanResult: ProspectHumanResult;
  updateActivityCount: () => void;
}

export function FacilityOverviewAccordion({ prospectHumanResult, updateActivityCount }: FacilityOverviewAccordionProps) {
  const { control } = useFormContext();

  return (
    <Accordion sx={{ boxShadow: "none", "&:before": { display: "none" }, padding: 1 }} defaultExpanded>
      {/* accordion header */}
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography fontWeight="bold">Facility Overview</Typography>
      </AccordionSummary>

      {/* accordion details */}
      <AccordionDetails>
        <Grid container spacing={2}>
          {/* name input field */}
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Name"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Default form state update
                    updateActivityCount(); // Additional callback
                  }}
                />
              )}
            />
          </Grid>

          {/* address */}
          <Grid item xs={12}>
            <CopyAddress address={determineProspectHumanResultAddress(prospectHumanResult)} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
