import { TextField } from "@mui/material";
import { useClaimsNavigatorStore } from "./useClaimsNavigatorStore";

type SearchBarForAllClaimsProps = {};

function SearchBarForAllClaims({}: SearchBarForAllClaimsProps) {
  const { searchQuery, setSearchQuery } = useClaimsNavigatorStore((state) => ({
    searchQuery: state.searchQuery,
    setSearchQuery: state.setSearchQuery,
  }));

  return (
    <TextField
      fullWidth
      label="Search claims"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      placeholder="Search by tenant name and email"
    />
  );
}

export default SearchBarForAllClaims;
