export const prospectHumanStatusMap = {
  fresh: {
    color: "primary" as const,
    label: "Review",
  },
  in_review: {
    color: "success" as const,
    label: "Done",
  },
  reviewed: {
    color: "primary" as const,
    label: "To Moderate",
  },
  moderated: {
    color: "success" as const,
    label: "Completed",
  },
};

export const isStorageFacilityMap = {
  yes: "Yes",
  no: "No",
  unknown: "Unknown",
  noPlaceIdFound: "No Place ID Found",
};

export enum RadioButtonGroupFormSelection {
  REVIEWER = "reviewer",
  ORIGINAL = "original",
  MODERATOR = "moderator",
}

export enum ProspectHumanResultStatusEnum {
  IN_REVIEW = "in_review",
  FRESH = "fresh",
  REVIEWED = "reviewed",
  MODERATED = "moderated",
  COMPLETE = "complete",
}
