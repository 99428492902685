import { SafeLeaseDataGrid } from "@safelease/components";
import { GridRowId, GridRowParams } from "@mui/x-data-grid-premium";
import { ProspectHumanResult } from "@safelease/service-utilities";
import { reviewerColumnDefs } from "./columnDefs";
import { ProspectPaginationModel } from "../components/ProspectDataTab";
import { tableStyles } from "../tables/ReviewerTable";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { FormattedProspectHumanResult } from "../../../types/Prospects";

interface ModeratorTableProps {
  formattedProspectHumanResults: FormattedProspectHumanResult[];
  totalCount: number;
  paginationModel: ProspectPaginationModel;
  setPaginationModel: (paginationModel: ProspectPaginationModel) => void;
  setSelectedForModerationIds: (selectedForModerationIds: string[]) => void;
}

export function ModeratorTable(props: ModeratorTableProps) {
  const [, setSearchParams] = useSearchParams();

  const ZeroState = () => {
    return (
      <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Typography>No prospects found</Typography>
      </Box>
    );
  };

  return (
    <SafeLeaseDataGrid
      checkboxSelection
      onRowSelectionModelChange={(ids: GridRowId[]) => {
        props.setSelectedForModerationIds(ids.map((id) => String(id)));
      }}
      disableRowSelectionOnClick
      rows={props.formattedProspectHumanResults}
      columns={reviewerColumnDefs}
      pagination
      paginationModel={props.paginationModel}
      onPaginationModelChange={props.setPaginationModel}
      paginationMode="server"
      pageSizeOptions={[10, 25, 50]} // Options for page size in the pagination bar
      rowCount={props.totalCount || 0}
      onRowClick={(params: GridRowParams<ProspectHumanResult>) => setSearchParams({ id: params.row.id })}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
            page: 0,
          },
        },
      }}
      loading={false}
      sx={tableStyles}
      hideFooter={false}
      slots={{ noRowsOverlay: ZeroState }}
    />
  );
}
