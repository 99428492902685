import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { SearchByWebsiteMenu } from "../SearchByWebsiteMenu";
import { ProspectHumanResult } from "@safelease/service-utilities";
import { Controller, useFormContext } from "react-hook-form";

interface WebsiteAccordionProps {
  prospectHumanResult: ProspectHumanResult;
  updateActivityCount: () => void;
}

export function WebsiteAccordion({ prospectHumanResult, updateActivityCount }: WebsiteAccordionProps) {
  const { control, register, setValue, watch } = useFormContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const websiteNotFound = watch("websiteNotFound");

  return (
    <Accordion sx={{ boxShadow: "none", "&:before": { display: "none" }, padding: 1 }} defaultExpanded>
      {/* accordion header */}
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography fontWeight="bold">Website</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {/* website url input field */}
          <Grid item xs={12}>
            <Controller
              name="website"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  disabled={websiteNotFound}
                  label="Website URL"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    updateActivityCount();
                  }}
                />
              )}
            />
          </Grid>

          {/* no website found checkbox */}
          <Grid item container alignItems="center" justifyContent="space-between">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("websiteNotFound")}
                    onChange={(e) => {
                      setValue("websiteNotFound", e.target.checked);
                      if (e.target.checked) {
                        setValue("website", "");
                      }
                      updateActivityCount();
                    }}
                  />
                }
                defaultChecked={websiteNotFound}
                label="No website found"
                checked={websiteNotFound}
              />
            </Grid>

            {/* find website dropdownbutton */}
            <Grid item>
              <Button
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
                variant="text"
                endIcon={<ExpandMore />}
              >
                Find website
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>

      {/* Find website menu options */}
      <SearchByWebsiteMenu prospectHumanResult={prospectHumanResult} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
    </Accordion>
  );
}
