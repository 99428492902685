import { ContentCopy, LocationOn } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";

export default function CopyAddress({ address }: { address: string }) {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item paddingRight={1}>
            <LocationOn />
          </Grid>
          <Grid item>
            <Typography>{address}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* copy address button */}
      <Grid item>
        <IconButton onClick={() => navigator.clipboard.writeText(address)}>
          <ContentCopy />
        </IconButton>
      </Grid>
    </Grid>
  );
}
