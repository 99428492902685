import { SafeLeaseDataGrid } from "@safelease/components";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { ProspectPaginationModel } from "../components/ProspectDataTab.tsx";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { columnDefs } from "./columnDefs.tsx";
import { tableStyles } from "./ReviewerTable.tsx";
import { useSearchParams } from "react-router-dom";
import { Prospect } from "@safelease/service-utilities";

export interface ProspectsTableProps {
  prospects: Prospect[];
  paginationModel: ProspectPaginationModel;
  isLoading: boolean;
  totalProspectCount: number;
  onPaginationModelChange?: (paginationModel: ProspectPaginationModel) => void;
}

export function ProspectsTable(props: ProspectsTableProps) {
  const [, setSearchParams] = useSearchParams();

  const ZeroState = () => {
    return (
      <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Typography>No prospects found</Typography>
      </Box>
    );
  };

  return (
    <SafeLeaseDataGrid
      rows={props.prospects}
      columns={columnDefs}
      pagination
      paginationModel={props.paginationModel}
      onPaginationModelChange={props.onPaginationModelChange}
      paginationMode="server"
      pageSizeOptions={[10, 25, 50]} // Options for page size in the pagination bar
      rowCount={props.totalProspectCount}
      onRowClick={(params: GridRowParams<Prospect>) => setSearchParams({ id: params.row.id })}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
            page: 0,
          },
        },
      }}
      loading={props.isLoading}
      sx={tableStyles}
      hideFooter={false}
      slots={{ noRowsOverlay: ZeroState }}
    />
  );
}
