import { SafeLeaseButton } from "@safelease/components";
import { getValidToken, useAuthStore } from "../../../../../hooks/useAuthStore";
import { appConfig } from "../../../../../config/app.config";
import { enqueueSnackbar } from "notistack";
import { Box, Tooltip } from "@mui/material";

type FmsLoginButtonProps = {
  locationId?: number;
};

function FmsLoginButton({ locationId }: FmsLoginButtonProps) {
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const currentUserHasAccessToFmsCreds = userAttributes?.hasAccessToFmsCreds === "true";
  const isChromeBrowser = typeof chrome !== "undefined";
  const chromeExtensionInstalled = isChromeBrowser && chrome?.runtime?.sendMessage;
  const handleClick = async () => {
    const jwtToken = await getValidToken();
    try {
      chrome.runtime.sendMessage(
        "gaeahfnmanflkoeckiegdhonkdfmnjgl",
        { type: "loginToFMS", payload: { locationId, jwtToken, baseSafeleaseApiUrl: appConfig.privatePolicyApiBaseUrl } },
        (response) => {
          if (response.status === "error") {
            // Since this is in the call back, we need to handle the error here.
            enqueueSnackbar("Error launching FMS login", { variant: "error" });
          }
        },
      );
    } catch (error) {
      // this is here to catch any errors that may occur sending the message to the extension
      enqueueSnackbar("Error launching FMS login", { variant: "error" });
    }
  };

  if (!isChromeBrowser || !locationId || !currentUserHasAccessToFmsCreds) {
    // If the locationId is not available, we don't want to show the button
    return null;
  }

  return (
    <Tooltip title={!chromeExtensionInstalled ? "FMS Login Chrome Extension is not installed correctly." : ""}>
      <Box>
        <SafeLeaseButton disabled={!chromeExtensionInstalled} variant="contained" size="small" onClick={handleClick}>
          FMS Login
        </SafeLeaseButton>
      </Box>
    </Tooltip>
  );
}

export { FmsLoginButton };
