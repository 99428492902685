import { GridColDef } from "@mui/x-data-grid-premium";
import { Chip, Grid } from "@mui/material";
import { Warning } from "@mui/icons-material";
import { Prospect } from "@safelease/service-utilities";

export const ProspectHumanResultStatus = {
  fresh: "Review",
  in_review: "Done",
  reviewed: "To Moderate",
};

export const columnDefs: GridColDef<Prospect>[] = [
  {
    field: "placeName",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => params.row.place?.name || params.row.sfdcLocation?.name || "—",
  },
  {
    field: "placeAddress",
    headerName: "Places Address",
    flex: 1,
    valueGetter: (params) =>
      params.row.place?.address
        ? `${params.row.place.address}, ${params.row.place.city}, ${params.row.place.state} ${params.row.place.postalCode}`
        : "—",
  },
  {
    field: "sfdcAddress",
    headerName: "Salesforce Address",
    flex: 1,
    valueGetter: (params) =>
      params.row.sfdcLocation?.address
        ? `${params.row.sfdcLocation.address}, ${params.row.sfdcLocation.city}, ${params.row.sfdcLocation.state} ${params.row.sfdcLocation.postalCode}`
        : "—",
  },
  {
    field: "coreFms",
    headerName: "FMS",
    flex: 1,
  },
  {
    field: "placeRating",
    headerName: "Places Rating",
    flex: 1,
    renderCell: (params) => params.row.place?.rating || "—",
  },
];

export const reviewerColumnDefs: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (params) =>
      params.value || (
        <Grid container alignItems="center" columnSpacing={1}>
          <Grid item>
            <Warning color="error" />
          </Grid>
          <Grid item>No name</Grid>
        </Grid>
      ),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => <Chip label={params.value} color={params.value === "Done" ? "success" : "primary"} />,
  },
  {
    field: "website",
    headerName: "Website",
    flex: 1,
    renderCell: (params) => (
      <Grid container alignItems="center" columnSpacing={1}>
        {params.value.notFound ? (
          <Grid item>No website found</Grid>
        ) : params.value.value ? (
          <Grid item>{params.value.value}</Grid>
        ) : (
          <>
            <Grid item>
              <Warning color="error" />
            </Grid>
            <Grid item>No website</Grid>
          </>
        )}
      </Grid>
    ),
  },
  {
    field: "placeId",
    headerName: "Place ID",
    flex: 1,
    renderCell: (params) => (
      <Grid container alignItems="center" columnSpacing={1}>
        {params.value.notFound ? (
          <Grid item>No place ID found</Grid>
        ) : params.value.value ? (
          <Grid item>{params.value.value}</Grid>
        ) : (
          <>
            <Grid item>
              <Warning color="error" />
            </Grid>
            <Grid item>No place ID</Grid>
          </>
        )}
      </Grid>
    ),
  },
];
