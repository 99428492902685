import { SafeLeaseDataGrid } from "@safelease/components";
import { Box } from "@mui/material";
import { ProspectHumanResult } from "@safelease/service-utilities/types/Prospects/prospect";
import Typography from "@mui/material/Typography";
import { reviewerColumnDefs } from "./columnDefs";
import { ProspectHumanResultStatus } from "./columnDefs";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { useSearchParams } from "react-router-dom";

export interface ReviewerTableProps {
  prospectHumanResults: ProspectHumanResult[];
  isLoading: boolean;
}

export function ReviewerTable(props: ReviewerTableProps) {
  const [, setSearchParams] = useSearchParams();
  const formattedProspectHumanResults = props.prospectHumanResults.map((prospectHumanResult) => {
    return {
      ...prospectHumanResult,
      status: ProspectHumanResultStatus[prospectHumanResult.status as keyof typeof ProspectHumanResultStatus] || "—",
      placeId: {
        value: prospectHumanResult.placeIdNew || prospectHumanResult.placeIdOld,
        notFound: prospectHumanResult.placeIdNotFound,
      },
      website: {
        value: prospectHumanResult.websiteNew || prospectHumanResult.websiteOld,
        notFound: prospectHumanResult.websiteNotFound,
      },
      name: prospectHumanResult.nameOld,
    };
  });

  const ZeroState = () => {
    return (
      <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Typography>No prospects found</Typography>
      </Box>
    );
  };

  return (
    <SafeLeaseDataGrid
      rows={formattedProspectHumanResults}
      columns={reviewerColumnDefs}
      onRowClick={(params: GridRowParams<ProspectHumanResult>) => setSearchParams({ id: params.row.id })}
      loading={props.isLoading}
      sx={tableStyles}
      hideFooter={false}
      slots={{ noRowsOverlay: ZeroState }}
    />
  );
}

// constants
export const tableStyles = {
  borderColor: "#EBEFF7",
  // Set the height and overflow for the DataGrid
  height: "100%", // Ensure it fills the Card height
  overflow: "auto", // Enable scrolling
  "& .MuiDataGrid-cell": {
    borderColor: "#EBEFF7",
    cursor: "pointer",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none", // Removes cell selection outline
  },
};
