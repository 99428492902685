import Grid from "@mui/material/Grid";
import { CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { ProspectsApi } from "../../../utils/apiInstances/ProspectsApiInstance.ts";
import { useQuery } from "@tanstack/react-query";
import ProspectsTabContainer from "../components/ProspectsTabContainer.tsx";
import { useNavigate, useParams } from "react-router";

export enum ProspectsTabEnum {
  DATA = "data",
  REVIEW = "review",
  MODERATOR = "moderator",
}

export default function ProspectsPage() {
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState<{ label: string; value: string }[]>([]);
  // This is to avoid a flash of no permissions
  const [isPermissionsSet, setIsPermissionsSet] = useState(false);
  const params = useParams();
  const tab = params.tab;

  const { data: userDetails, isLoading: isUserDetailsLoading } = useQuery({
    queryKey: ["userDetails"],
    queryFn: async () => {
      // Setting permissions based on user details
      // This is used to determine which tabs to show to the user
      const permissions = [];
      const response = await ProspectsApi.getUserDetails();

      if (response.data.readAccessProspects) {
        permissions.push({
          label: "Prospect Data",
          value: ProspectsTabEnum.DATA,
        });
      }
      if (response.data.isReviewer) {
        permissions.push({
          label: "Reviewer",
          value: ProspectsTabEnum.REVIEW,
        });
      }
      if (response.data.isModerator) {
        permissions.push({
          label: "Moderator",
          value: ProspectsTabEnum.MODERATOR,
        });
      }
      setPermissions(permissions);
      setIsPermissionsSet(true);

      // After setting permissions, handle routing
      if (permissions.length > 0) {
        // If no tab is selected, route to first available tab
        if (!tab) {
          navigate(`/prospects/${permissions[0].value}`);
          // if they don't have access to the tab, they will be redirected to 404
          // this is handling URL manipulation
        } else if (!permissions.some((permission) => permission.value === tab)) {
          navigate("/404");
        }
      }
      return response.data;
    },
  });

  if (isUserDetailsLoading || !isPermissionsSet) {
    return <CircularProgress />;
  }

  // If the user has no permissions, show a message
  if (permissions.length === 0 || !userDetails) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography>{`You don't have access to prospect data. Please contact the Prospects team for access.`}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProspectsTabContainer permissions={permissions} />
      </Grid>
    </Grid>
  );
}
