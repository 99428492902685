import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { ProspectsApi } from "../../../../utils/apiInstances/ProspectsApiInstance";
import { ProspectsTabEnum } from "../../pages/ProspectsPage";
import { ProspectsReviewDetailsDrawer } from "../../pages/ProspectsReviewDetailsDrawer";
import { ReviewerTable } from "../../tables/ReviewerTable";
import { ProspectHumanResultStatusEnum } from "../../constants";

export function ReviewerTab() {
  const params = useParams();
  const tab = params["tab"];
  const [searchParams] = useSearchParams();
  const entityId = searchParams.get("id");

  const { data, refetch: refetchGetProspectHumanResultsForReviewer } = useQuery({
    queryKey: ["getProspectHumanResultsForReviewer"],
    queryFn: async () => {
      const response = await ProspectsApi.getProspectHumanResultsForReviewer();
      return response.data;
    },
  });

  const onSubmitProspectHumanResultsToModerator = useMutation({
    mutationFn: async () => {
      if (data?.prospectHumanResults.length === 0) {
        return;
      }
      await ProspectsApi.updateProspectHumanResultsForReviewer(data?.prospectHumanResults.map((result) => result.id) || []);
    },
    onSuccess: () => {
      refetchGetProspectHumanResultsForReviewer();
    },
    onError: () => {
      window.alert("An error occurred while marking the prospect as done");
    },
  });

  const freshProspectHumanResultsLength = data?.prospectHumanResults.filter(
    (result) => result.status === ProspectHumanResultStatusEnum.FRESH,
  ).length;
  const doneProspectHumanResultsLength = data?.prospectHumanResults.filter(
    (result) => result.status === ProspectHumanResultStatusEnum.IN_REVIEW,
  ).length;

  const showSubmitButton =
    data?.prospectHumanResults.length &&
    data?.prospectHumanResults.length > 0 &&
    doneProspectHumanResultsLength === data?.prospectHumanResults.length;

  return (
    <Grid container rowGap={2} columnSpacing={2} paddingBottom={2}>
      {/* to review card */}
      <Grid item xs={12} md={6}>
        <CardMetric label="To Review" value={freshProspectHumanResultsLength || 0} />
      </Grid>

      {/* done card */}
      <Grid item xs={12} md={6}>
        <CardMetric label="Done" value={doneProspectHumanResultsLength || 0} />
      </Grid>

      {/* reviewer table */}
      <Grid item xs={12}>
        <ReviewerTable prospectHumanResults={data?.prospectHumanResults || []} isLoading={false} />
      </Grid>

      {/* submit all to moderator button */}
      {/* We only want to show the submit button if they have reviewed all 10 ProspectHumanResults */}
      {showSubmitButton && (
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={() => onSubmitProspectHumanResultsToModerator.mutate()}>
            Submit to moderator
          </Button>
        </Grid>
      )}

      {/* details drawer */}
      {entityId && tab === ProspectsTabEnum.REVIEW && <ProspectsReviewDetailsDrawer />}
    </Grid>
  );
}

const CardMetric = ({ label, value }: { label: string; value: number }) => {
  return (
    <Card elevation={0} sx={{ border: `1px solid #EBEFF7`, height: "100%" }}>
      <CardContent>
        <Typography color="textSecondary">{label}</Typography>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 600 }}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};
