import { Stack } from "@mui/material";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { AuthServiceApi } from "../../../../../utils/apiInstances/AuthServiceApiInstance";
import { enqueueSnackbar } from "notistack";
import { UserType } from "@aws-sdk/client-cognito-identity-provider";
import { StyledToolEnabledToggleFormElement } from "../../../../../components/ServiceUIComponents/UserManagementService/StyledToolEnabledToggleFormElement";
import {
  DescriptionOutlined,
  Grading,
  PeopleOutline,
  Troubleshoot,
  AdminPanelSettingsOutlined,
  LocationOnOutlined,
  VpnKeyOutlined,
} from "@mui/icons-material";
import { SafeLeaseButton } from "@safelease/components";

type UserAttributeFormValues = {
  email: string;
  phoneNumber: string;
  temporaryPassword: string;
  isClaimsEnabled: "true" | "false";
  isComplianceEnabled: "true" | "false";
  isJobCenterEnabled: "true" | "false";
  isUserSettingsAdmin: "true" | "false";
  isTenantUserAdmin: "true" | "false";
  isAdminReqsEnabled: "true" | "false";
  isProspectsEnabled: "true" | "false";
  hasAccessToFmsCreds: "true" | "false";
};

type UserAttributeEditFormProps = {
  user: UserType;
  setOpen: (open: boolean) => void;
};

function UserAttributeEditForm({ user, setOpen }: UserAttributeEditFormProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const useFormMethods = useForm<UserAttributeFormValues>({
    defaultValues: {
      isClaimsEnabled: (user.Attributes?.find((attr) => attr.Name === "custom:isClaimsEnabled")?.Value as "true" | "false") ?? "false",
      isComplianceEnabled:
        (user.Attributes?.find((attr) => attr.Name === "custom:isComplianceEnabled")?.Value as "true" | "false") ?? "false",
      isJobCenterEnabled:
        (user.Attributes?.find((attr) => attr.Name === "custom:isJobCenterEnabled")?.Value as "true" | "false") ?? "false",
      isUserSettingsAdmin:
        (user.Attributes?.find((attr) => attr.Name === "custom:isUserSettingsAdmin")?.Value as "true" | "false") ?? "false",
      isTenantUserAdmin: (user.Attributes?.find((attr) => attr.Name === "custom:isTenantUserAdmin")?.Value as "true" | "false") ?? "false",
      isAdminReqsEnabled:
        (user.Attributes?.find((attr) => attr.Name === "custom:isAdminReqsEnabled")?.Value as "true" | "false") ?? "false",
      isProspectsEnabled:
        (user.Attributes?.find((attr) => attr.Name === "custom:isProspectsEnabled")?.Value as "true" | "false") ?? "false",
      hasAccessToFmsCreds:
        (user.Attributes?.find((attr) => attr.Name === "custom:hasAccessToFmsCreds")?.Value as "true" | "false") ?? "false",
    },
  });

  const { handleSubmit, control } = useFormMethods;

  const onUpdateButtonClicked: SubmitHandler<UserAttributeFormValues> = async (data) => {
    setLoading(true);

    const userEmail = user.Attributes?.find((attr) => attr.Name === "email")?.Value ?? "";
    try {
      // loop through all custom attributes and update each one
      for (const [key, value] of Object.entries(data)) {
        await AuthServiceApi.updateCustomAttributeForInternalUser({
          email: userEmail,
          customAttributeName: key,
          newValue: value,
        });
      }
      enqueueSnackbar("User updated successfully.", { variant: "success" });
      window.location.reload(); // very important. this will cause the page to reload and the user to be updated in the table by causing a refetch of the users list
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong. Please try again later.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(onUpdateButtonClicked)} style={{ height: "100%" }}>
        <Stack height="100%" spacing={2}>
          <StyledToolEnabledToggleFormElement
            name="isClaimsEnabled"
            label="Claims enabled"
            control={control}
            icon={<DescriptionOutlined />}
          />
          <StyledToolEnabledToggleFormElement
            name="isComplianceEnabled"
            label="Private policy compliance enabled"
            control={control}
            icon={<Grading />}
          />
          <StyledToolEnabledToggleFormElement
            name="isJobCenterEnabled"
            label="Job error center enabled"
            control={control}
            icon={<Troubleshoot />}
          />
          <StyledToolEnabledToggleFormElement
            name="isUserSettingsAdmin"
            label="Internal user admin enabled"
            control={control}
            icon={<PeopleOutline />}
          />
          <StyledToolEnabledToggleFormElement
            name="isTenantUserAdmin"
            label="Tenant user admin enabled"
            control={control}
            icon={<PeopleOutline />}
          />
          <StyledToolEnabledToggleFormElement
            name="isAdminReqsEnabled"
            label="Data change manager enabled"
            control={control}
            icon={<AdminPanelSettingsOutlined />}
          />
          <StyledToolEnabledToggleFormElement
            name="isProspectsEnabled"
            label="Prospects enabled"
            control={control}
            icon={<LocationOnOutlined />}
          />
          <StyledToolEnabledToggleFormElement
            name="hasAccessToFmsCreds"
            label="Access to FMS credentials"
            control={control}
            icon={<VpnKeyOutlined />}
          />
          <Stack direction="row" spacing={1}>
            <SafeLeaseButton onClick={() => setOpen(false)} variant="outlined" color="navy" sx={{ width: "100%" }} disabled={loading}>
              Cancel
            </SafeLeaseButton>
            <SafeLeaseButton type="submit" variant="contained" sx={{ width: "100%" }} loading={loading}>
              Update
            </SafeLeaseButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

export default UserAttributeEditForm;
