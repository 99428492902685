import { Box, CircularProgress, Divider, Drawer, IconButton, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { ProspectsApi } from "../../../utils/apiInstances/ProspectsApiInstance.ts";
import { Close as CloseIcon, HouseSiding, Laptop, Monitor } from "@mui/icons-material";
import { FiveStarRating } from "../../../components/FiveStarRating.tsx";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { ProspectsTabEnum } from "./ProspectsPage.tsx";

export function ProspectDetailsDrawer() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const prospectId = searchParams.get("id");

  if (!prospectId) {
    return null;
  }

  const { data, isLoading } = useQuery({
    queryKey: ["getProspectById", prospectId],
    queryFn: async () => {
      const response = await ProspectsApi.getProspectById(prospectId);
      return response.data;
    },
  });

  const prospectDetails = [
    {
      value: data?.coreFms || "",
      icon: <Laptop />,
      link: false,
    },
    {
      value: `REIT: ${data?.regexFinding?.reit || "—"}`,
      icon: <HouseSiding />,
      link: false,
    },
    {
      value: data?.place?.website || "—",
      icon: <Monitor />,
      link: true,
    },
  ];

  return (
    <Drawer
      anchor="right"
      onClose={() => navigate(`/prospects/${ProspectsTabEnum.DATA}`)}
      open={!!prospectId}
      PaperProps={{ sx: { width: "50%" } }}
    >
      {isLoading || !data ? (
        <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ backgroundColor: "white", p: 2 }}>
              <Typography variant="h4">{data.place?.name ? data.place.name : data.sfdcLocation?.name || "—"}</Typography>
              {(data.place || data.sfdcLocation) && (
                <Typography variant="caption" sx={{ color: "gray" }}>
                  {`Places address: ${data.place?.address} | Salesforce address: ${data.sfdcLocation?.address}`}
                </Typography>
              )}
            </Box>
            <IconButton
              sx={{ position: "absolute", top: 16, right: 16 }}
              disabled={false}
              onClick={() => navigate(`/prospects/${ProspectsTabEnum.DATA}`)}
            >
              <CloseIcon />
            </IconButton>
            <Box padding={2}>
              <Grid item container justifyContent={"space-between"} alignItems={"center"}>
                {data.place?.rating && data.place?.reviewCount && (
                  <Grid item>
                    <FiveStarRating rating={data.place?.rating} count={data.place?.reviewCount} />
                  </Grid>
                )}
                {data?.refreshedAt && (
                  <Grid item>
                    <Typography variant="caption" sx={{ color: "gray" }}>
                      Last assessed on {dayjs(data.refreshedAt).format("MM/DD/YY")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container padding={2} rowGap={1}>
            {prospectDetails.map((detail) =>
              detail.value ? (
                <Grid item container key={detail.value} columnGap={1}>
                  {detail.icon}
                  {detail.link ? (
                    <Link onClick={() => window.open(`${detail.value}`, "_blank")}>Website</Link>
                  ) : (
                    <Typography variant="body1">{detail.value}</Typography>
                  )}
                </Grid>
              ) : null,
            )}
            {data.sfdcLocation?.accountOwner && (
              <Typography variant="body1">{`Account owner: ${data.sfdcLocation?.accountOwner}`}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      )}
    </Drawer>
  );
}
