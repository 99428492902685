import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import CopyAddress from "../CopyAddress";
import { ExpandMore } from "@mui/icons-material";
import { RadioButtonGroupForm } from "./RadioButtonGroupForm";
import { ProspectHumanResult } from "@safelease/service-utilities";
import { determineProspectHumanResultAddress } from "../../../../utils/helpers/prospects";

export const FacilityOverviewAccordion = ({ prospectHumanResult }: { prospectHumanResult: ProspectHumanResult }) => {
  const address = determineProspectHumanResultAddress(prospectHumanResult);

  return (
    <Accordion sx={{ boxShadow: "none", "&:before": { display: "none" }, padding: 1 }} defaultExpanded>
      {/* accordion header */}
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography fontWeight="bold">Facility Overview</Typography>
      </AccordionSummary>

      {/* accordion details */}
      <AccordionDetails>
        <Grid container spacing={2}>
          {/* name input field */}
          <Grid item xs={12}>
            <RadioButtonGroupForm
              fieldName="name"
              defaultValueNew={prospectHumanResult.nameNew || "No Name"}
              defaultValueOld={prospectHumanResult.nameOld || "No Name"}
            />
          </Grid>

          {/* address */}
          <Grid item xs={12}>
            <CopyAddress address={address} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
