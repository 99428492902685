import Grid from "@mui/material/Grid";
import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { ProspectDataTab } from "./ProspectDataTab";
import { ProspectsTabEnum } from "../pages/ProspectsPage";
import { ModeratorTab } from "./moderators/ModeratorTab";
import { ReviewerTab } from "./reviewers/ReviewerTab";

interface ProspectsTabContainerProps {
  permissions: { label: string; value: string }[];
}

export default function ProspectsTabContainer({ permissions }: ProspectsTabContainerProps) {
  const params = useParams();
  const tab = params["tab"];
  const navigate = useNavigate();

  const handleTabChange = (_: React.SyntheticEvent, newTab: string) => {
    navigate(`/prospects/${newTab}`);
  };

  return (
    <Grid container spacing={2}>
      {/* tabs */}
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tab} onChange={handleTabChange}>
            {permissions.map((permission) => (
              <Tab key={permission.value} label={permission.label} value={permission.value} />
            ))}
          </Tabs>
        </Box>
      </Grid>

      {/* tab content */}
      <Grid item xs={12}>
        {tab === ProspectsTabEnum.DATA && <ProspectDataTab />}
        {tab === ProspectsTabEnum.REVIEW && <ReviewerTab />}
        {tab === ProspectsTabEnum.MODERATOR && <ModeratorTab />}
      </Grid>
    </Grid>
  );
}
