import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseFormTextField } from "@safelease/components";
import { forwardRef, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import { z } from "zod";
import "react-phone-number-input/style.css";
import {
  DescriptionOutlined,
  Grading,
  Troubleshoot,
  PeopleOutline,
  AdminPanelSettingsOutlined,
  LocationOnOutlined,
  VpnKeyOutlined,
} from "@mui/icons-material";
import { AuthServiceApi } from "../../../../utils/apiInstances/AuthServiceApiInstance";
import { enqueueSnackbar } from "notistack";
import { StyledToolEnabledToggleFormElement } from "../../../../components/ServiceUIComponents/UserManagementService/StyledToolEnabledToggleFormElement";

type SignUpFormProps = {};

type SignUpFormValues = {
  email: string;
  phoneNumber: string;
  temporaryPassword: string;
  isClaimsEnabled: "true" | "false";
  isComplianceEnabled: "true" | "false";
  isJobCenterEnabled: "true" | "false";
  isUserSettingsAdmin: "true" | "false";
  isAdminReqsEnabled: "true" | "false";
  isProspectsEnabled: "true" | "false";
  hasAccessToFmsCreds: "true" | "false";
};

const validationSchema = z.object({
  email: z.string().trim().min(1, "Email is required.").email(), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to the backend
  temporaryPassword: z
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
    .regex(/[0-9]/, "Password must contain at least one number")
    .regex(/[^A-Za-z0-9]/, "Password must contain at least one special character"),
  phoneNumber: z
    .string()
    .min(1, "Phone number is required.")
    .refine((data) => isPossiblePhoneNumber(data, "US"), "Invalid phone number."),
  isClaimsEnabled: z.string().refine((val) => val === "true" || val === "false"),
  isComplianceEnabled: z.string().refine((val) => val === "true" || val === "false"),
  isJobCenterEnabled: z.string().refine((val) => val === "true" || val === "false"),
  isUserSettingsAdmin: z.string().refine((val) => val === "true" || val === "false"),
  isAdminReqsEnabled: z.string().refine((val) => val === "true" || val === "false"),
  isProspectsEnabled: z.string().refine((val) => val === "true" || val === "false"),
  hasAccessToFmsCreds: z.string().refine((val) => val === "true" || val === "false"),
});

function SignUpForm({}: SignUpFormProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const useFormMethods = useForm<SignUpFormValues>({
    defaultValues: {
      phoneNumber: "",
      email: "",
      temporaryPassword: "",
      isClaimsEnabled: "false",
      isComplianceEnabled: "false",
      isJobCenterEnabled: "false",
      isUserSettingsAdmin: "false",
      isAdminReqsEnabled: "false",
      isProspectsEnabled: "false",
      hasAccessToFmsCreds: "false",
    },
    resolver: zodResolver(validationSchema),
  });

  const { control, formState, handleSubmit, reset } = useFormMethods;

  const onSignupButtonClicked: SubmitHandler<SignUpFormValues> = async (data) => {
    setLoading(true);

    const sanitizedData = {
      ...data,
      email: data.email.trim(), // removing all leading and trailing white spaces from the email
      phoneNumber: data.phoneNumber.replace(/\s/g, ""), // removing all white spaces from the phone number
    };

    try {
      await AuthServiceApi.createNewInternalUser(sanitizedData);
      enqueueSnackbar("User created successfully.", { variant: "success" });
      reset();
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong. Please try again later.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(onSignupButtonClicked)} style={{ height: "100%" }}>
        <Stack height="100%" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Box flex={1}>
              <StyledSafeLeaseFormTextField
                name="email"
                helperText={formState.errors.email?.message}
                error={!!formState.errors.email?.message}
                label="Email"
                placeholder="Ex: Alex@gmail.com"
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <>
                    {/* Needed to add the extra typography at this level because the inputComponent only targets the textField to the right of the country flag icon which made the label and helper texts look weird */}
                    <Typography sx={{ fontSize: 14, fontWeight: 500, padding: 0 }} component="label">
                      Phone number
                    </Typography>
                    <PhoneInput
                      placeholder="Enter phone number"
                      defaultCountry="US"
                      international
                      inputComponent={StyledSafeLeaseFormTextField}
                      error={!!formState.errors.phoneNumber?.message}
                      helperText={formState.errors.phoneNumber?.message}
                      fullWidth
                      {...field}
                    />
                  </>
                )}
              />
            </Box>
          </Stack>

          <StyledSafeLeaseFormTextField
            name="temporaryPassword"
            helperText={formState.errors.temporaryPassword?.message}
            error={!!formState.errors.temporaryPassword?.message}
            label="Temporary password"
            placeholder="********"
          />
          <Stack>
            <StyledToolEnabledToggleFormElement
              name="isClaimsEnabled"
              label="Claims enabled"
              control={control}
              icon={<DescriptionOutlined />}
            />
            <StyledToolEnabledToggleFormElement
              name="isJobCenterEnabled"
              label="Job error center enabled"
              control={control}
              icon={<Troubleshoot />}
            />
            <StyledToolEnabledToggleFormElement
              name="isComplianceEnabled"
              label="Private policy compliance enabled"
              control={control}
              icon={<Grading />}
            />
            <StyledToolEnabledToggleFormElement
              name="isUserSettingsAdmin"
              label="User settings enabled"
              control={control}
              icon={<PeopleOutline />}
            />
            <StyledToolEnabledToggleFormElement
              name="isAdminReqsEnabled"
              label="Admin requests enabled"
              control={control}
              icon={<AdminPanelSettingsOutlined />}
            />
            <StyledToolEnabledToggleFormElement
              name="isProspectsEnabled"
              label="Prospects enabled"
              control={control}
              icon={<LocationOnOutlined />}
            />
            <StyledToolEnabledToggleFormElement
              name="hasAccessToFmsCreds"
              label="Access to FMS credentials"
              control={control}
              icon={<VpnKeyOutlined />}
            />
          </Stack>

          <SafeLeaseButton type="submit" variant="contained" sx={{ width: "100%" }} loading={loading}>
            Create account
          </SafeLeaseButton>
        </Stack>
      </form>
    </FormProvider>
  );
}
const StyledSafeLeaseFormTextField = forwardRef((props: any, ref) => {
  return (
    <SafeLeaseFormTextField
      inputRef={ref}
      labelProps={{
        sx: {
          fontSize: 14,
          fontWeight: 500,
        },
      }}
      {...props}
    />
  );
});

export { SignUpForm };
