import { SafeLeaseDataGrid } from "@safelease/components";
import { columnDefs } from "./columnDefs";
import {
  GridPaginationModel,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { ClaimsNavigatorTableRow } from "@safelease/service-utilities";
import { useNavigate } from "react-router";
import { useClaimsNavigatorStore } from "../useClaimsNavigatorStore";
import { useEffect } from "react";

type ClaimsTableProps = {
  claimsList: ClaimsNavigatorTableRow[];
  paginationModel: GridPaginationModel;
  setPaginationModel: (paginationModel: GridPaginationModel) => void;
  totalRows?: number;
};

function ClaimsTable({ claimsList, paginationModel, setPaginationModel, totalRows }: ClaimsTableProps) {
  const navigate = useNavigate();
  const setDataGridApiRef = useClaimsNavigatorStore((state) => state.setDataGridApiRef);

  const dataGridApiRef = useGridApiRef();

  /**
   * Set the data grid api ref in the store
   * We will use this ref to apply filters from other components on the page
   */
  useEffect(() => {
    setDataGridApiRef(dataGridApiRef);
  }, [setDataGridApiRef, dataGridApiRef]);

  return (
    <>
      <SafeLeaseDataGrid
        rows={claimsList}
        columns={columnDefs}
        onRowClick={(params: GridRowParams<ClaimsNavigatorTableRow>, event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>) => {
          const baseUrl = params.row.isLegacyClaim ? "/legacy-claims/" : "/claims/";
          if (event.metaKey || event.ctrlKey) {
            // Open in new tab when cmd/ctrl clicked
            window.open(baseUrl + params.row.id, "_blank");
            return;
          }
          navigate(baseUrl + params.row.id);
        }}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={totalRows}
        paginationMode="server"
        pageSizeOptions={[50, 100, 200]} // Options for page size in the pagination bar
        apiRef={dataGridApiRef}
        sx={{
          // Target the rows and customize the border below each row
          ".MuiDataGrid-row": {
            cursor: "pointer",
            "&:not(:last-of-type)": {
              // Target all but the last row to add a border
              borderBottom: "2px solid rgba(235, 239, 247, 1)", // Customize the border color and thickness
            },
          },
          ".MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid rgba(235, 239, 247, 1)", // Customize the border color and thickness for the header
          },
          padding: "16px",
        }}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          panel: {
            sx: {
              ".MuiSwitch-sizeSmall": {
                width: "44px",
              },
            },
          },
        }}
        hideFooter={false}
      />
    </>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton color="navy" />
      <GridToolbarExport color="navy" />
    </GridToolbarContainer>
  );
}

export { ClaimsTable };
