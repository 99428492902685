import { useFormContext, Controller } from "react-hook-form";
import { RadioGroup, Radio, FormControl, FormControlLabel, TextField } from "@mui/material";
import { RadioButtonGroupFormSelection } from "../../constants";

export function RadioButtonGroupForm({
  fieldName,
  defaultValueNew,
  defaultValueOld,
}: {
  fieldName: string;
  defaultValueNew: string;
  defaultValueOld: string;
}) {
  const { control, watch } = useFormContext();
  const selectedValue = watch(`${fieldName}.selection`);

  return (
    <FormControl fullWidth>
      <Controller
        name={`${fieldName}.selection`}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} sx={{ rowGap: 1, width: "100%" }}>
            <FormControlLabel
              value={RadioButtonGroupFormSelection.REVIEWER}
              control={<Radio />}
              label={`Reviewer ${fieldName} input: ${defaultValueNew}`}
            />
            <FormControlLabel
              value={RadioButtonGroupFormSelection.ORIGINAL}
              control={<Radio />}
              label={`Original ${fieldName} input: ${defaultValueOld}`}
            />
            <FormControlLabel
              value={RadioButtonGroupFormSelection.MODERATOR}
              control={<Radio />}
              sx={{
                width: "100%",
                "& .MuiFormControlLabel-label": {
                  width: "100%", // Make the label container take full width
                },
              }}
              label={
                <Controller
                  name={`${fieldName}.moderatorInput`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={`Moderator ${fieldName} input`}
                      variant="outlined"
                      size="small"
                      disabled={selectedValue !== RadioButtonGroupFormSelection.MODERATOR}
                    />
                  )}
                />
              }
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
