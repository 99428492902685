import { Divider, Stack, Typography } from "@mui/material";
import { SafeLeaseCard, SafeLeaseChip } from "@safelease/components";
import { ClaimStatusChip } from "../../../../../components/ServiceUIComponents/ClaimsService/ClaimStatusChip";
import { useSelectedClaimPageStore } from "../useSelectedClaimPageStore";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import { ClaimStatus, RelationshipProtectionType } from "@safelease/service-utilities/enums";
import { ActionButtonsSection } from "./ActionButtonsSection";
import { ClaimsApi } from "../../../../../utils/apiInstances/ClaimsApiInstance";
import { enqueueSnackbar } from "notistack";
import { ClickableTypography } from "../../../../../components/ClickableTypography";
import { useState } from "react";
import { ClaimantNameOverrideModal } from "./ClaimantNameOverrideModal";
import { PaymentHistory } from "./PaymentHistory";
import { PolicyDetails } from "./PolicyDetails";
import { UnitDetails } from "./UnitDetails";
import { DateOfLossDetails } from "./DateOfLossDetails";
import { EmailFacilityDropdown } from "./EmailFacilityDropdown";
import { FmsLoginButton } from "./FmsLoginButton";

type HeaderCardProps = {};

const activeClaimStatuses = [ClaimStatus.NeedsReview, ClaimStatus.NewClaim, ClaimStatus.NeedsApproval, ClaimStatus.TenantActionNeeded];

function HeaderCard({}: HeaderCardProps) {
  const [claimantNameOverrideModalOpen, setClaimantNameOverrideModalOpen] = useState(false);
  const { claim, refetchClaim, setClaim } = useSelectedClaimPageStore((state) => ({
    claim: state.claim,
    refetchClaim: state.refetchClaim,
    setClaim: state.setClaim,
  }));

  const userName = `${claim?.unit?.cognitoUserDetail?.userFirstName} ${claim?.unit?.cognitoUserDetail?.userLastName}`;
  const claimantName = claim?.claimantNameOverride ? `${claim.claimantNameOverride} (${userName})` : userName;
  const isClaimStatusChipEditable = activeClaimStatuses.includes(claim?.status as ClaimStatus);
  const claimStatusChipDropdownOptions = claim?.closedAt
    ? [ClaimStatus.ClosedSettled, ClaimStatus.ClosedDenied, ...activeClaimStatuses]
    : activeClaimStatuses;

  // This function is passed as a callback function to the ClaimStatusChip component
  const onClaimStatusOptionClicked = async (selectedStatus: ClaimStatus) => {
    const claimId = claim?.id;
    if (!claimId || selectedStatus === claim.status) return;
    try {
      //Create the Tenant Action for the given type based on which button was clicked
      await ClaimsApi.updateClaim(claimId, {
        status: selectedStatus,
      });
      const newClaim = {
        ...claim,
        status: selectedStatus,
      };
      //update the global state of the claim so that it is reflected in the UI while the refetch is happening
      setClaim(newClaim);
      // this will update the UI to show the new status of the claim
      refetchClaim();
    } catch (error) {
      enqueueSnackbar("Error denying the claim, please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <SafeLeaseCard
      sx={{
        padding: "16px",
        overflow: "visible",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="column" spacing={0.5}>
            <ClickableTypography onClick={() => setClaimantNameOverrideModalOpen(true)} sx={{ fontSize: 20, fontWeight: 500 }}>
              {claimantName}
            </ClickableTypography>
            <Typography sx={{ fontSize: 14 }}>{claim?.unit?.cognitoUserDetail?.userEmail}</Typography>
          </Stack>
          <ClaimStatusChip
            status={claim?.status as ClaimStatus}
            editable={isClaimStatusChipEditable}
            dropdownOptions={claimStatusChipDropdownOptions}
            onStatusClicked={onClaimStatusOptionClicked}
          />
          <Stack spacing={1.5} direction="row" alignItems={"center"} divider={<Divider orientation="vertical" flexItem />}>
            <UnitDetails />
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{claim?.claimType?.name}</Typography>
            {claim?.unit?.safeleaseEnrolledPrivatePolicies && claim.unit.safeleaseEnrolledPrivatePolicies.length > 0 && (
              <SafeLeaseChip
                label={"$0 Deductible via Tenant Enrollment"}
                sx={{
                  paddingY: 2,
                  bgcolor: "rgba(239, 184, 6, 0.3)",
                  color: "rgba(3, 30, 48, 1)",
                }}
              />
            )}
            <DateOfLossDetails />
            <PolicyDetails />
          </Stack>
        </Stack>
        <ActionButtonsSection />
      </Stack>
      <Divider sx={{ marginY: 2 }} />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <LocationOnOutlinedIcon sx={{ fontSize: 22 }} />
            <Typography sx={{ fontSize: 12 }}>
              {claim?.unit?.safeleaseLocationDetail?.address}, {claim?.unit?.safeleaseLocationDetail?.city},{" "}
              {claim?.unit?.safeleaseLocationDetail?.state} {claim?.unit?.safeleaseLocationDetail?.postalCode}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <HandshakeOutlinedIcon sx={{ fontSize: 22 }} />
            <Typography sx={{ fontSize: 12 }}>{claim?.unit?.safeleaseLocationDetail?.relationshipName}</Typography>
            {/* Either display that the claim is a Storage protectors claim or if it is a safelease claim, display the type of protection */}
            {claim?.isThirdParty ? (
              <SafeLeaseChip
                label="Storage Protectors"
                sx={{ paddingY: 2, bgcolor: "rgba(199, 56, 46, 0.4)", color: "rgba(3, 30, 48, 1)" }}
              />
            ) : (
              <SafeLeaseChip
                label={
                  claim?.unit?.safeleaseLocationDetail?.relationshipProtectionType === RelationshipProtectionType.TENANT_INSURANCE
                    ? "Tenant Insurance"
                    : "Tenant Protection"
                }
                sx={{
                  paddingY: 2,
                  bgcolor: `${claim?.unit?.safeleaseLocationDetail?.relationshipProtectionType === RelationshipProtectionType.TENANT_INSURANCE ? "rgba(239, 184, 6, 0.3)" : "rgba(203, 223, 246, 1)"}`,
                  color: "rgba(3, 30, 48, 1)",
                }}
              />
            )}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <WarehouseOutlinedIcon sx={{ fontSize: 22 }} />
            <Typography sx={{ fontSize: 12 }}>{claim?.unit?.safeleaseLocationDetail?.llcName}</Typography>
          </Stack>
          <PaymentHistory />
          <FmsLoginButton locationId={claim?.unit?.safeleaseLocationDetail?.locationId} />
        </Stack>
        <EmailFacilityDropdown />
      </Stack>
      {/* Modal for adjusters to change the claimantNameOverride field */}
      {claimantNameOverrideModalOpen && <ClaimantNameOverrideModal setOpen={setClaimantNameOverrideModalOpen} />}
    </SafeLeaseCard>
  );
}

export { HeaderCard };
